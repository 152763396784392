import * as yup from 'yup'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import buildTranslator from './locale'

export default class Form {
  constructor(elements, config) {
    this.elements = elements
    this.config = config

    if (this.elements.password.type !== 'password') {
      this.elements.password.type = 'password'
    }
    if (this.elements.birthday) {
      this.elements.birthday.autocomplete = 'off'
    }

    this.translator = buildTranslator(this.config.locale)

    if (!this.formDomIsValid()) {
      throw this.translator.t('form.not_valid')
    }

    const genderValidationSchema = this.elements.gender
      ? {
          gender: yup
            .string()
            .required()
            .label(this.translator.t('form.fields.gender.label')),
        }
      : {}
    const birthdayValidationSchema = this.elements.birthday
      ? {
          birthday: yup
            .date()
            .required()
            .nullable()
            .typeError(
              this.translator.t('form.fields.birthday.validations.invalid_date')
            )
            .label(this.translator.t('form.fields.birthday.label')),
        }
      : {}

    const validationShape = {
      email: yup
        .string()
        .email()
        .required()
        .label(this.translator.t('form.fields.email.label')),
      first_name: yup
        .string()
        .required()
        .label(this.translator.t('form.fields.first_name.label')),
      last_name: yup
        .string()
        .required()
        .label(this.translator.t('form.fields.last_name.label')),
      password: yup
        .string()
        .required()
        .min(8, this.translator.t('form.fields.password.validations.too_short'))
        .max(72, this.translator.t('form.fields.password.validations.too_long'))
        .label(this.translator.t('form.fields.password.label')),
      ...birthdayValidationSchema,
      ...genderValidationSchema,
    }

    this.schema = yup.object().shape(validationShape)

    this.bindEvents()
  }

  formDomIsValid = () => {
    ;['email', 'first_name', 'last_name', 'password', 'submit_button'].forEach(
      (key) => {
        if (!this.elements[key]) {
          throw this.translator.t('form.no_element_found', { field: key })
        }
      }
    )
    return true
  }

  bindEvents = () => {
    this.elements.submit_button.addEventListener('click', (e) => {
      e.preventDefault()
      this.beforeSubmit()
      this.handleSubmit()

      return false
    })
  }

  beforeSubmit = () => {
    if (this.onBeforeSubmitCallback) {
      this.onBeforeSubmitCallback()
    }
    this.setErrors([])
    this.changeSubmitButtonDisabled(true)
  }

  setErrors = (errors) => {
    Object.keys(this.elements)
      .filter((k) => !!this.elements[k])
      .forEach((elementKey) => {
        const element = this.elements[elementKey]

        const error = errors.find((e) => e.field === elementKey)

        if (!error) {
          element.classList.remove(this.config.styles.inputErrorClass)
        }

        if (error && !this.config.useCustomValidationElement) {
          element.classList.add(this.config.styles.inputErrorClass)
          tippy(`#${element.id}`, {
            content: error.message,
            showOnCreate: true,
            placement: 'bottom-start',
          })
        }
      })
  }

  handleSubmit = () => {
    this.schema
      .validate(this.data(), { abortEarly: false })
      .then(() => {
        this.onSubmitCallback(this.data())
      })
      .catch((error) => {
        this.handleValitationFailed(error.inner)
      })
  }

  onSubmit = (callback) => {
    this.onSubmitCallback = callback
  }

  onValidateFailed = (callback) => {
    this.onValidateFailedCallback = callback
  }

  onRequestSucceeded = (callback) => {
    this.onRequestSucceededCallback = callback
  }

  handleRequestSucceeded = (value) => {
    this.changeSubmitButtonDisabled(false)
    this.onRequestSucceededCallback(value)
  }

  changeSubmitButtonDisabled = (disabled) => {
    this.elements.submit_button.disabled = disabled
  }

  onRequestFailed = (callback) => {
    this.onRequestFailedCallback = callback
  }

  handleRequestFailed = (error) => {
    this.changeSubmitButtonDisabled(false)
    this.onRequestFailedCallback(error)
  }

  onBeforeSubmit = (callback) => {
    this.onBeforeSubmitCallback = callback
  }

  handleValitationFailed = (errors) => {
    this.changeSubmitButtonDisabled(false)

    this.setErrors(errors.map((e) => ({ field: e.path, message: e.message })))

    if (this.onValidateFailedCallback) {
      this.onValidateFailedCallback(
        errors.map((e) => ({ field: e.path, message: e.message }))
      )
    }
  }

  elementValue = (id) => this.elements[id].value

  data = () => {
    const data = {
      email: this.elementValue('email'),
      first_name: this.elementValue('first_name'),
      last_name: this.elementValue('last_name'),
      password: this.elementValue('password'),
    }

    if (this.elements.promo_code) {
      data.promo_code = this.elementValue('promo_code')
    }
    if (this.elements.birthday) {
      data.birthday = this.elementValue('birthday')
    }
    if (this.elements.gender) {
      data.gender = this.elementValue('gender').toLowerCase()
    }
    return data
  }
}
