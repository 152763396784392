import Pikaday from 'pikaday'
import { createUser } from './api'
import buildConfig from './config'
import Form from './Form'
import buildTranslator from './locale'

import errorMessagesTemplate from '../templates/errorMessages.html'

import '../node_modules/pikaday/css/pikaday.css'

export default class SignupWidget {
  constructor(config) {
    this.config = buildConfig(config)
    this.translator = buildTranslator(this.config.locale)

    this.urlParams = this.readParams()

    this.mountContainer()
    this.mountForm()
  }

  readParams = () => {
    const params = {}
    const urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.forEach((value, key) => {
      params[key] = value
    })

    return params
  }

  mountContainer = () => {
    this.containerElement = document.querySelector(
      this.config.mapping.container
    )
    if (!this.containerElement) {
      throw this.translator.t('no_container_found', {
        selector: this.config.mapping.container,
      })
    }
  }

  mountForm = () => {
    const elements = this.buildElements()

    this.form = new Form(elements, this.config)
    if (this.form) {
      this.form.onValidateFailed(this.config.callbacks.onValidateFailed)
      this.form.onRequestSucceeded(this.handleRequestSucceeded)
      this.form.onRequestFailed(this.handleRequestFailed)
      this.form.onBeforeSubmit(this.handleBeforeSubmit)
      this.form.onSubmit(this.handleSubmit)
    }

    if (!this.config.useCustomErrorElement) {
      this.errorMessagesElement = this.buildErrorMessagesElement(
        this.containerElement.clientHeight
      )
      const tryAgainButton =
        this.errorMessagesElement.querySelector('.try-again-btn')
      tryAgainButton.addEventListener('click', () => {
        this.errorMessagesElement.style.display = 'none'
      })

      this.containerElement.prepend(this.errorMessagesElement)
    }
  }

  buildErrorMessagesElement = (containerHeight) => {
    const container = document.createElement('div')
    container.classList.add(this.config.styles.errorMessagesContainerClass)
    container.innerHTML = errorMessagesTemplate

    const element = container.firstChild

    element.style.height = `${containerHeight}px`

    const list = element.querySelector('ul')
    list.classList.add(this.config.styles.errorListClass)

    return element
  }

  buildElements = () => {
    const elements = {}
    const form = this.containerElement.querySelector('form')
    elements.form = form
    Object.keys(this.config.mapping.fields).forEach((key) => {
      elements[key] = form.querySelector(this.config.mapping.fields[key])
    })

    elements.submit_button = elements.form.querySelector(
      'button[type="submit"]'
    )

    if (elements.birthday) {
      new Pikaday({
        field: document.getElementById('birthday'),
        yearRange: [1930, new Date().getFullYear()],
      })
    }

    return elements
  }

  handleSubmit = (values) => {
    if (this.config.test.forceRequestError) {
      return this.handleRequestFailed([this.translator.t('request.error_test')])
    }

    const requestParams = {
      ...this.urlParams,
      ...this.config.params,
      ...values,
      program_code: this.config.programCode,
      sign_up_configuration_id: this.config.configurationId,
      agree_to_tos: true,
    }

    createUser(requestParams, {
      'Content-Type': 'application/json',
    })
      .then((response) => {
        response
          .json()
          .then((body) =>
            response.ok
              ? this.handleRequestSucceeded(body)
              : this.handleRequestFailed(this.requestError(body))
          )
      })
      .catch(() => this.handleRequestDefaultError())

    return true
  }

  requestError = (body) => {
    if (body?.flash?.error) {
      return [body.flash.error]
    }
    return body?.errors?.full_messages || this.defaultErrorMessage()
  }

  handleRequestDefaultError = () => {
    this.handleRequestFailed([this.translator.t('request.default_error')])
  }

  formatErrorMessages = (errors) =>
    errors.full_messages
      ? errors.full_messages
      : [this.translator.t('request.default_error')]

  handleBeforeSubmit = () => {
    this.setErrorMessages([])
  }

  handleRequestSucceeded = (value) => {
    this.form.changeSubmitButtonDisabled(false)
    if (this.config.callbacks.onRequestSucceeded) {
      this.config.callbacks.onRequestSucceeded()
    }
    window.location.replace(value.after_sign_in_path)
  }

  handleRequestFailed = (errors) => {
    this.setErrorMessages(errors)
    this.form.changeSubmitButtonDisabled(false)
    this.config.callbacks.onRequestFailed(errors)
  }

  setErrorMessages = (errors) => {
    if (this.config.useCustomErrorElement) {
      return false
    }
    if (this.errorMessagesElement) {
      const errorsList = this.errorMessagesElement.querySelector(
        `.${this.config.styles.errorListClass}`
      )
      errorsList.childNodes.forEach((node) => {
        errorsList.removeChild(node)
      })
      ;(errors || []).forEach((error) => {
        const li = document.createElement('li')
        const span = document.createElement('span')
        span.innerHTML = error
        li.appendChild(span)
        errorsList.appendChild(li)
      })

      if (!errors || errors.length > 0) {
        this.errorMessagesElement.style.display = ''
      }
    }
    return true
  }
}
