import { merge } from 'lodash'
import * as yup from 'yup'

const defaultConfig = {
  mode: 'production',
  locale: 'en',
  mapping: {
    container: '.ms-signup',
    fields: {
      email: '#email',
      first_name: '#first_name',
      last_name: '#last_name',
      password: '#password',
      promo_code: '#promo_code',
      birthday: '#birthday',
      gender: '#gender',
    },
  },
  validation: {
    showErrorMessage: true,
  },
  useCustomErrorElement: false,
  useCustomValidationElement: false,
  callbacks: {
    onValidateFailed: () => {},
    onRequestFailed: () => {},
    onRequestSucceeded: () => {},
  },
  test: {
    forceRequestError: false,
  },
  styles: {
    inputErrorClass: 'ms-input-error',
    errorMessagesContainerClass: 'ms-error-messages-container',
    errorListClass: 'ms-error-messages',
  },
}

const configSchema = yup.object().shape({
  programCode: yup
    .string()
    .oneOf(['CW', 'NW', 'UA', 'ERN', 'TW', 'CTQ', 'BBS'])
    .required(),
  locale: yup.string().oneOf(['en', 'es']).required(),
})

export default (config) =>
  configSchema.validateSync(merge(defaultConfig, config))
